.weoffercontainer {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.weoffer-main-partofthebox {
  padding: 3rem 5rem 5rem;
  color: #fff;
  position: relative;
  z-index: 2;
}

.weoffer-maincontainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.mian-we-are-page .weoffer-maincontainer {
  margin-bottom: 7rem;
}
.mian-we-are-page .weoffer-maincontainer:last-child {
  margin-bottom: 0;
}
.weoffer-maincontainer-2 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 4rem;
}
.heading-box {
  padding: 0 1rem;
  position: relative;
}
.heading-box .overlay-nuberinf {
  position: absolute;
  top: -95px;
  left: -40px;
  font-size: 11rem;
  font-weight: 900;
  -webkit-text-stroke: 3px rgba(237, 27, 44, 0.4);
  color: transparent;
  z-index: -1;
}
.weoffer-maincontainer-2 .heading-box .overlay-nuberinf {
  top: -85px;
  left: unset;
  right: -40px;
}
.weoffer-maincontainer-2 .heading-box h2 {
  text-align: end;
}
.heading-box h1,
.heading-box p {
  margin: 0%;
  line-height: 1.5;
}
.weoffer-data-mianpage .whatweoffer-services {
  padding: 0%;
}
.detailes-box p {
  margin-top: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
}
.detailes-box {
  padding: 0 2rem;
}
.box-for-background-weoffer-circule {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 25vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-weoffer-circule-1 {
  position: absolute;
  top: 30rem;
  left: 10rem;
  transform: rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 20vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-weoffer-circule-2 {
  position: absolute;
  bottom: 50rem;
  right: 10rem;
  transform: rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 25vw;
  height: 25vw;
  border-radius: 50em;
  opacity: 0.2;
}
.box-for-background-weoffer-circule-4 {
  position: absolute;
  bottom: 10rem;
  left: -10rem;
  transform: rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 25vw;
  height: 25vw;
  border-radius: 50em;
  opacity: 0.4;
}
.whatweoffer-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.weoffer-maincontainer-2 .whatweoffer-services {
  justify-content: flex-end;
}
.whatweoffer-services-inner-box {
  display: grid;
  margin-bottom: 1.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  padding: 1rem;
  grid-template-columns: 1fr 4fr;
  border-radius: 20px;
}
.whatweoffer-services-inner-box:nth-child(1) {
  border: 1px solid rgba(237, 27, 44, 0.3);
}
.whatweoffer-services-inner-box:nth-child(2) {
  border: 1px solid rgba(255, 203, 49, 0.3);
}
.whatweoffer-services-inner-box:nth-child(4) {
  border: 1px solid rgba(237, 27, 44, 0.3);
}
.whatweoffer-services-inner-box:nth-child(3) {
  border: 1px solid rgba(255, 203, 49, 0.3);
}
.weoffer-main-heading {
  text-align: center;
  padding: 5rem 0 5rem;
}
.services-img-box {
  border-radius: 50rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.weoffer-main-heading h1,
.weoffer-main-heading p {
  margin: 0%;
  line-height: 1.5;
}
.weoffer-main-heading p {
  font-weight: 300;
  font-size: 18px;
}
.weoffer-maincontainer .heading-box h2 {
  margin-top: 0%;
  font-size: 1.7em;
  font-weight: 500;
}
.services-detailes-box {
  padding: 0 0 0 1rem;
}
.services-detailes-box p {
  margin: 0%;
  display: grid;
  grid-template-columns: 1fr 9fr;
}
.services-detailes-box {
  margin: 0%;
}
.services-detailes-box h3 {
  margin-top: 0%;
  font-size: 20px;
  margin-bottom: 0.5rem;
}
.show-more-btn {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.more-btn {
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  color: #fff;
  padding: 0.7rem 2.5rem 0.6rem;
  font-size: 18px;
  font-weight: 300;
  border-radius: 1rem;
  transition: all 0.5s ease;
  cursor: pointer;
  position: relative;
  border: none;
}
.more-btn:after {
  content: "";
  position: absolute;
  inset: -0.1em;
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  filter: blur(0.5em);
  z-index: -1;
  opacity: 0.7;
  transition: all 0.5s ease;
}
.more-btn:hover:after {
  inset: -0.2em;
  filter: blur(1em);
  opacity: 1;
}
@media only screen and (max-width: 769px) {
  .weoffer-main-partofthebox {
    padding: 4rem 1rem;
  }
  .weoffer-maincontainer {
    display: block;
  }
  .weoffer-maincontainer-2 {
    display: grid;
    grid-template-columns: 100%;
  }
  .weoffer-maincontainer-2 .heading-box .overlay-nuberinf {
    top: -81px;
    left: 5px;
  }
  .weoffer-maincontainer-2 .heading-box h2 {
    text-align: start;
  }
  .weoffer-maincontainer-2 .heading-box {
    order: 1;
  }
  .weoffer-maincontainer-2 .detailes-box.whatweoffer-services {
    order: 2;
  }
  .whatweoffer-services {
    display: block;
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 3rem;
  }
  .heading-box .overlay-nuberinf {
    font-size: 8rem;
    top: -81px;
    left: 5px;
  }
  .whatweoffer-services-inner-box {
    margin-left: 0%;
    margin-right: 0%;
  }
  .services-img-box-outer {
    padding: 0% !important;
  }
  .box-for-background-weoffer-circule {
    top: 10rem;
    left: 10rem;
    width: 300px;
    height: 200px;
  }
  .box-for-background-weoffer-circule-1 {
    top: 45rem;
    left: -5rem;
    width: 250px;
    height: 250px;
  }
  .box-for-background-weoffer-circule-2 {
    bottom: 50%;
    right: unset;
    left: 10rem;
    width: 250px;
    height: 250px;
    opacity: 0.4;
  }
  .box-for-background-weoffer-circule-4 {
    bottom: 50rem;
    left: -5rem;
    width: 250px;
    height: 250px;
    opacity: 0.5;
  }
}
