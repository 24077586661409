.footer_wrapper {
  position: relative;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 3rem 5rem;
  color: #000;
  background: linear-gradient(45deg, #ed1b2e, #ffcc31);
  color: white;
}
.footer_inner_main {
  position: relative;
}
.footer_head {
  position: absolute;
  display: inline-block;
  left: 28px;
  top: 75px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.footer_head p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  margin: 0%;
}
.footer_head_discreaption {
  padding: 0%;
  display: flex;
  align-items: center;
}
.footer_head_discreaption-inner-detailes {
  width: 100%;
}
.footer_head_discreaption span {
  text-transform: capitalize;
  font-size: 2em;
  line-height: 1.143em;
  font-weight: 900;
  color: #000;
}
.footer_head_discreaption span p {
  margin-bottom: 0%;
  font-size: 2rem;
  font-weight: 600;
}
.footer_inner_discreaption {
  -webkit-text-stroke: 1px black;
  color: transparent !important;
  font-size: 100px !important;
  text-transform: uppercase !important;
  transition: background 0.5s ease all;
}
.contact-us-headding-fotter {
  color: white !important;

  background-image: linear-gradient(
    to right,
    #ed1b2e,
    #ffcc31,
    #ffcc31,
    #ffcc31
  );

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.footer_inner_discreaption:hover {
  -webkit-text-stroke: 1px #ffcc31;
  color: white !important;

  background-image: linear-gradient(to right, #ed1b2e, #ffcc31);

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.footer_head_discreaption p {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  margin: 0%;
}
.footer_head_discreaption a {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  transition: all 0.5s ease;
}
.footer_head_discreaption a:hover {
  color: #000;
}
.footer_head_discreaption h3 {
  margin-top: 0%;
  margin-bottom: 0.8rem;
}

.footer_inner_detailes {
  display: flex;
  flex-direction: column;
  width: 33.33%;
}

.footer_inner_detailes.mapfooter {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.footer_inner_detailes .map {
  border-radius: 15px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  mix-blend-mode: luminosity;
}
.footer_inner_detailes p {
  margin: 0%;
}
.socialmedia_container img {
  margin-right: 0.5rem !important;
}
.hrtage {
  margin: 1.5rem 0;
}
.bottom_footer {
  display: flex;
  justify-content: space-between;
}

.footer_topbox {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.footer_detailes_box {
  display: flex;
  align-items: start;
}
.social-media-links img {
  margin-right: 1rem;
}
.footer_head_discreaption .footer-logo {
  padding: 0 3rem;
}
.bottom-footer-policy p {
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .footer_wrapper {
    padding: 2rem;
  }
  .footer_inner_detailes {
    width: 220px;
  }
  .footer_detailes_box {
    flex-wrap: wrap;
  }
  .footer_inner_detailes {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 769px) {
  .footer_wrapper {
    position: relative;
    padding: 2rem 1rem 0;
  }
  .footer_head_discreaption {
    padding: 0 0 3rem 0;
  }
  .footer_head {
    transform: rotate(0);
    position: unset;
    padding: 0% 3%;
  }
  .footer_head p {
    letter-spacing: 1rem;
    margin: 0.5rem 0 2rem;
  }
  .footer_head_discreaption span {
    font-size: 31px;
  }
  .footer_inner_discreaption {
    font-size: 50px !important;
  }
  .footer_detailes_box {
    display: block;
    height: 100%;
  }
  .bottom_footer p {
    float: unset;
    text-align: center;
  }
  .bottom_footer div {
    float: unset;
    text-align: center;
  }
  .footer_inner_detailes {
    height: auto;
  }
  .footer_topbox {
    padding: 0%;
    display: block;
  }
  .footer_head_discreaption hr {
    margin-top: 0%;
  }
  .bottom_footer {
    display: block;
  }
  .bottom-footer-policy {
    display: block !important;
    margin-bottom: 2rem;
  }

  .footer_inner_detailes {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .footer_head_discreaption {
    padding-bottom: 2rem;
  }
  .footer_head_discreaption .footer-logo {
    width: 200px;
    padding: 0%;
  }
  .footer_head_discreaption {
    justify-content: center;
  }
}
