.cloud-detailes-wrapper {
  background-color: white;
  padding: 2rem 5rem;
}
.cloud-detailes-wrapper-headding {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
}
.gridsystem-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.detailes-wrapper-headding-left {
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 0 3rem;
}
.detailes-wrapper-headding-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detailes-wrapper-headding-right h3 {
  font-size: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.detailes-wrapper-headding-right p {
  margin-top: 0%;
  margin-bottom: 0;
}
.Cloude-services-wrapper h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  background-color: white;

  background-image: linear-gradient(
    to right,
    #ed1b2e,
    #ffcc31,
    #ffcc31,
    #ffcc31
  );

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.Cloude-services-wrapper ul li {
  font-size: 16px;
  line-height: 1.5;
}
.Cloude-services-wrapper ul li ul li {
  font-size: 15px;
  line-height: 1.3;
}
.Cloude-services-wrapper-inner {
  padding: 0 15px;
}
.Cloude-services-wrapper-inner p {
  font-size: 17px;
}
.Cloude-services-wrapper-inner ul li {
  font-size: 16px;
  line-height: 1.5;
}
.Cloude-services-wrapper-inner .devopsServiceline {
  font-size: 16px;
  line-height: 1.5;
  margin: 0.8rem 0 1rem;
  padding-left: 2rem;
}
.Our_DevSecOps_services_wrapper {
  color: white;
  padding: 2rem 4rem;
  border-radius: 25px;
  margin: 0 3rem;
  background: linear-gradient(to right, #ed1b2e, #ffcc31);
}
.Our_DevSecOps_services_wrapper h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.Our_DevSecOps_services_wrapper_inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Our_DevSecOps_services_wrapper_inner ul {
  line-height: 1.5;
}
@media only screen and (max-width: 1100px) {
  .gridsystem-2 {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .cloud-detailes-wrapper {
    padding: 2rem;
  }
  .detailes-wrapper-headding-left {
    padding: 0 0.5rem;
  }
  .Our_DevSecOps_services_wrapper {
    padding: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .cloud-detailes-wrapper {
    padding: 2rem 1rem;
  }
  .cloud-detailes-wrapper-headding {
    display: block;
  }
  .detailes-wrapper-headding-left {
    width: 100%;
    justify-content: start;
    padding: 0%;
  }
  .detailes-wrapper-headding-left img {
    width: 200px;
  }
  .Our_DevSecOps_services_wrapper_inner {
    display: block;
  }
  .Our_DevSecOps_services_wrapper_inner ul {
    margin-bottom: 0%;
    padding-left: 1.5rem;
  }
  .Our_DevSecOps_services_wrapper_inner:nth-child(2) ul {
    margin-top: 0%;
  }
  .Our_DevSecOps_services_wrapper {
    margin: 0%;
    padding: 1rem;
  }
  .Cloude-services-wrapper-inner .devopsServiceline {
    padding: 0%;
  }
}
