.contact_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  color: #000;
  padding: 5rem 5rem 7rem;
  font-size: 18px;
  background: white;
}
.contact_container {
  position: relative;
  z-index: 2;
}
.contact_head {
  position: absolute;
  display: inline-block;
  left: 28px;
  top: 50px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.contact_head p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  margin: 0%;
}
.contact_head_discreaption {
  display: flex;
  flex-direction: column;
}
.contact_head_discreaption a {
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  transition: all 0.5s ease;
}
.contact_head_discreaption span {
  text-transform: capitalize;
  font-size: 42px;
  line-height: 1.143em;
  font-weight: 900;
  color: #000;
  display: flex;
  flex-wrap: wrap;
}
.textformotion {
  transition: all 0.15s ease;
}
.contact_inner_discreaption {
  -webkit-text-stroke: 1px #000;
  color: transparent !important;
  transition: all 0.15s ease;
}
.contact_inner_discreaption:hover {
  -webkit-text-stroke: 1px #000;
  color: #000 !important;
}
.contact_head_discreaption p {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
}
.contectimgcontainer {
  position: relative;
  z-index: 2;
}
.contectimgcontainer span {
  height: 100% !important;
}
.howwehelpimh {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.contact_head_discreaption h3 {
  padding-top: 2rem;
  margin-bottom: 0%;
}
.contact_form {
  display: flex;
  flex-direction: column;
}
.contact_form input {
  padding: 0.5rem 0.2rem;
  margin: 0 0 2.5rem 0;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-weight: 600;
  outline: none;
  background-color: transparent;
  color: #000;
}
.contact_form textarea {
  background-color: transparent;
  color: #000;
  padding: 0.5rem 0.2rem;
  border: 2px solid rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-weight: 600;
  outline: none;
  margin-bottom: 2rem;
}
.contact_form label {
  padding: 0.5rem 0.2rem;
  font-size: 17px;
  font-weight: 600;
  outline: none;
}
.contact_form button {
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  color: #fff;
  padding: 0.7rem 2rem 0.6rem;
  font-size: 18px;
  font-weight: 300;
  border-radius: 1rem;
  transition: all 0.5s ease;
  cursor: pointer;
  position: relative;
  border: none;
  transition: 0.5s ease all;
}

.contact_form button:hover {
  font-weight: 600;
}
.contactloddingofbutton {
  width: 100%;
  display: none;
  animation: mymove 2s infinite;
}
.contactus-main-wrapper a:hover {
  background: unset;
  -webkit-text-fill-color: white;
}
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Contacttsuceesmsg {
  width: 100%;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  height: 100%;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.contactunsuccessmsg {
  width: 100%;
  height: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.6rem 1.25rem;
  margin: 0%;
  display: none;
}
.contactbuttunncommentcontainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.contact-detiles-and-heade .contact-withus {
  font-weight: 500;
}
.contact-wraperforrheading {
  padding-top: 23vh;
  padding-bottom: 8rem;
  height: 85vh;
}
.hamburger-links-box:after {
  content: "";
  position: absolute;
  inset: -0.005em;
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  filter: blur(2em);
  z-index: -1;
  opacity: 0.7;
}
.hamburger-links-box {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%) !important;
  background: linear-gradient(
    to bottom right,
    rgba(237, 27, 44, 0.5),
    rgba(255, 203, 49, 0.5)
  );
  padding: 1rem 3rem;
  border-radius: 1rem;
}
.hamburger-links-box p {
  margin: 0%;
}
.box-for-background-contact-circule-1 {
  position: absolute;
  top: 15rem;
  left: 7rem;
  transform: translateY(-80%) rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 30vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-contact-circule-2 {
  position: absolute;
  top: 10rem;
  right: 10rem;
  transform: translateY(75%);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 20vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-contact-circule-3 {
  position: absolute;
  bottom: 00%;
  left: 10rem;
  transform: translateY(75%);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 25vw;
  height: 25vw;
  border-radius: 50em;
  opacity: 0.3;
}
.contact-wraperforrheading .hero-main-partofthebox {
  position: relative;
  height: 100%;
}
.contactus-main-wrapper .hero-overlay {
  background: linear-gradient(to left, #ffcc31, rgb(237, 27, 44));
  opacity: 1;
  z-index: -1;
}
.contact-hero-wrapper {
  height: 100%;
}
.contact-hero-wrapper-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 2.5fr 3fr;
  color: white;
}
.contact-wrapper-grid-left {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
.contact-grid-left-inner {
  position: relative;
  height: 100%;
}
.contact-grid-left-inner-headding {
  display: inline-block;
  left: 70%;
  top: 70%;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
}
.contact-grid-left-inner-headding p {
  margin: 0%;
  color: #fff;
  font-size: 5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.contact-grid-left-inner-detailes {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
}
.contact-grid-left-inner-detailes p {
  text-align: left;
}
.contact-wrapper-grid-right {
  position: relative;
}
.contactus-main-wrapper .herowrapper {
  overflow: unset;
}
.contactus-main-wrapper .hero-main-partofthebox {
  overflow: unset;
}
.contact-wrapper-grid-right .mapwithback {
  height: calc(100% - 100px);
  position: absolute;
  width: 100%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  border: none;
}
.contact-grid-left-inner-detailes > div h3 {
  font-size: 1.4em;
}
.contact-grid-left-inner-detailes > div p {
  font-size: 1.2em;
}
.contact-grid-left-inner-detailes > div a {
  font-size: 1.2em;
}
@media only screen and (max-width: 769px) {
  .contect_main_wrappper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .contact_wrapper {
    padding: 0 1rem;
    display: block;
  }
  .contact_head_discreaption span {
    font-size: 31px;
  }

  .contact_head_discreaption {
    padding: 0%;
  }
  .contact_head {
    transform: rotate(0);
    position: unset;
    padding: 0%;
  }
  .contact_head p {
    letter-spacing: 1rem;
    margin: 0.5rem 0;
  }
  .contact_container {
    padding-bottom: 3rem;
  }
  .contact_form {
    padding: 0%;
  }
  .hamburger-links-box {
    padding: 1rem;
    width: 80%;
  }
  .contact-hero-wrapper-grid {
    display: block;
  }
  .contact-wrapper-grid-left {
    display: block;
  }
  .contact-grid-left-inner-headding {
    display: block;
    position: unset;
    transform: rotate(0deg);
  }
  .contact-wrapper-grid-right {
    padding: 1rem;
    height: calc(100% - 460px) !important;
  }
  .contact-wrapper-grid-right .mapwithback {
    position: unset !important;
    width: 100%;
    height: 100% !important;
  }
  .contact-wrapper-grid-left {
    padding-top: 120px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 1.5rem;
  }
  .contact-grid-left-inner-headding p {
    text-align: start;
    font-size: 3.5rem;
  }
  .contact_wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
