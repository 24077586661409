.herowrapper {
  background-color: #fff;
  margin-top: 0;
  height: 90vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.manntopwrapper {
  background-color: #fff;
}
.hero-main-partofthebox {
  color: #000;
  position: relative;
  z-index: 2;
  height: 100%;
  overflow: hidden;
}
.hero-main-partofthebox .videoTag {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
.headdingherosection {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  color: white;
}
.headdingherosection h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  text-align: center;
  margin: 0%;
}
.headdingherosection h2 {
  /*   text-shadow: -1px -1px 2px #ed1b2e, 1px -1px 2px #ffcc31, -1px 1px 2px #ffcc31,
  1px 1px 2px #ed1b2e; */
  background: linear-gradient(45deg, #ed1b2e, #ffcc31, #ffcc31);
  background-size: 200%, 200%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  color: white;
}

.hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 10;
  background: linear-gradient(
    to top,
    #ffcc31,
    rgba(237, 27, 44, 0.4),
    transparent,
    transparent
  );
  opacity: 0.5;
}
.hero-main-partofthebox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.hero-main-partofthebox h1 {
  text-align: center;
  font-size: 3.5vw;
  font-weight: 500;
  margin-bottom: 0%;
  padding: 0 10vw;
}
.hero-main-partofthebox p {
  text-align: center;
  margin-bottom: 1rem;
}
.box-for-background {
  color: #000;
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  filter: blur(6.5em);
  z-index: 0;
}
.box-for-background-inner {
  position: relative;
  height: 100%;
  width: 100%;
}
.box-for-background-circule {
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translateY(-80%) rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 30vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-min-circule {
  position: absolute;
  bottom: 50%;
  right: 5%;
  transform: translateY(75%);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 21vw;
  height: 21vw;
  border-radius: 50em;
  opacity: 0.4;
}
.heroimages {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: center;
  padding-top: 10vh;
}
.middle-side-herosection {
  display: flex;
  justify-content: center;
}
.left-side-herosection {
  position: relative;
}
.left-side-inner-one {
  display: flex;
  position: absolute;
  top: 25%;
  left: 20%;
}
.left-side-inner-two {
  display: flex;
  position: absolute;
  bottom: 0%;
  right: 0%;
}
.right-side-herosection {
  position: relative;
}
.right-side-inner-one {
  display: flex;
  position: absolute;
  top: 10%;
  right: 25%;
}
.right-side-inner-two {
  display: flex;
  position: absolute;
  bottom: 15%;
  right: 50%;
}
.storage-images {
  background-color: rgba(237, 27, 44, 0.4);
  border: 1px solid #ed1b2e;
  border-radius: 20px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  color: #ffcc31;
  height: 80px;
  width: auto;
}
.storage-images div {
  background-color: rgba(255, 203, 49, 0.5);
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.storage-images div img {
  height: 70%;
  padding: 0.2rem;
}
.storage-images.cloude {
  height: 110px;
  position: relative;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.back-line-rioration {
  position: absolute;
  margin-right: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(193, 118, 38, 0.1) 33%,
    rgba(255, 203, 49, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
  z-index: -1;
}
.storage-images.cloude div {
  border-radius: 50rem;
  height: 75px;
  width: 75px;
  background-color: rgba(193, 118, 38, 0.5);
}
.storage-images.cloude div img {
  height: 60%;
  padding: 0.2rem;
}
.innerg1 {
  animation: 2.32s linear -0s infinite normal forwards running breath-d85a2f6c-6634-4764-bdca-f2f88417fa15;
}
.innerg2 {
  animation: 2.32s linear -0.04s infinite normal forwards running breath-d85a2f6c-6634-4764-bdca-f2f88417fa15;
}
.hero-main-partofthebox h1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@keyframes breath-d85a2f6c-6634-4764-bdca-f2f88417fa15 {
  0% {
    transform: scale(0.9999999999999999);
    transform-origin: center;
  }
  51% {
    transform: scale(1.01);
    transform-origin: center;
  }
  100% {
    transform: scale(0.9999999999999999);
    transform-origin: center;
  }
}
@keyframes rotet {
  0% {
    transform: rotate(0);
    transform-origin: center;
  }
  51% {
    transform: rotate(180deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}
.recapinfo-wrapper {
  background: linear-gradient(45deg, #ed1b2e, #ffcc31);
  color: white;
}
.recapinfo-wrapper-inner {
  padding: 4rem 5rem;
}
.recapinfo-wrapper-inner p {
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.5;
}
@media only screen and (max-width: 769px) {
  .heroimages {
    display: block;
  }
  .right-side-herosection {
    display: none;
  }
  .hero-main-partofthebox {
  }
  .left-side-herosection {
    display: none;
  }
  .hero-main-partofthebox h1 {
    font-size: 25px;
    padding: 0%;
  }
  .middle-side-herosection img {
    width: 100%;
    object-fit: cover;
    object-position: 46%;
  }
  .recapinfo-wrapper-inner p {
    font-size: 21px;
  }
  .recapinfo-wrapper-inner {
    padding: 1rem;
  }
  .headdingherosection {
    transform: unset;
    left: 0%;
    right: 0%;
    bottom: 24%;
  }
  .headdingherosection h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 769px) and (orientation: portrait) {
}
