.Career_form_wrapper {
  padding: 0rem 5rem 7rem;
  background-color: white;
  color: #000;
}
.Career_form_container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.Career_form_container {
  display: flex;
  flex-direction: column;
}
.Career_form_container input {
  padding: 0.5rem 0.2rem;
  margin: 0 0 2.5rem 0;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-weight: 600;
  outline: none;
  background-color: transparent;
  color: #000;
}
.Career_form_container textarea {
  background-color: transparent;
  color: #000;
  padding: 0.5rem 0.2rem;
  border: 2px solid rgba(0, 0, 0, 0.5);
  font-size: 17px;
  font-weight: 600;
  outline: none;
  margin-bottom: 2rem;
}
.Career_form_container label {
  padding: 0.5rem 0.2rem;
  font-size: 17px;
  font-weight: 600;
  outline: none;
}
.Career_form_container button {
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  color: #fff;
  padding: 0.7rem 2rem 0.6rem;
  font-size: 18px;
  font-weight: 300;
  border-radius: 1rem;
  transition: all 0.5s ease;
  cursor: pointer;
  position: relative;
  border: none;
  transition: 01s ease font-weight;
}

.Career_form_container button:hover {
  font-weight: 600;
}
.career_form_sidebyside {
  display: flex;
  width: 100% !important;
}
.career_form_sidebyside input {
  width: 50%;
}
.career_form_sidebyside input:nth-child(1) {
  margin-right: 1rem;
}
.career_form_sidebyside input:nth-child(2) {
  margin-left: 1rem;
}
.Career_head {
  position: absolute;
  display: inline-block;
  left: 28px;
  top: 25px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.Career_head p {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  margin: 0%;
}
.last_form {
  display: flex;
  flex-direction: column;
}
.careerloddingofbutton {
  width: 100%;
  display: none;
  animation: mymove 2s infinite;
}
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.careertsuceesmsg {
  width: 100%;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 0.25rem;
  height: 100%;
  padding: 1.2rem 1.25rem;
  margin: 0%;
  display: none;
}
.careerunsuccessmsg {
  width: 100%;
  height: 100%;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.7rem 1.25rem;
  margin: 0%;
  display: none;
}
.careerbuttunncommentcontainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.box-for-background-carrer-circule-1 {
  position: absolute;
  top: 15rem;
  left: 7rem;
  transform: translateY(-80%) rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 30vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-carrer-circule-2 {
  position: absolute;
  top: 10rem;
  right: 10rem;
  transform: translateY(75%);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 20vw;
  height: 20vw;
  border-radius: 50em;
  opacity: 0.4;
}
.box-for-background-carrer-circule-3 {
  position: absolute;
  bottom: 30rem;
  left: -10rem;
  transform: translateY(75%);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 25vw;
  height: 25vw;
  border-radius: 50em;
  opacity: 0.3;
}
.opning-box-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: #000;
  padding: 0 0 3rem 0;
}
.current-openings h4 {
  margin-top: 0%;
  margin-bottom: 1.5rem;
  font-size: 20px;
}
.current-openings p {
  margin: 0%;
}
.current-openings {
  border: 1px solid rgba(255, 203, 49, 0.5);
  margin: 0 1rem 0 0;
  padding: 1.5rem;
  border-radius: 1rem;
}
.current-opning-image img {
  opacity: 0.7;
}
.current-opning-image {
  height: 150px;
  overflow: hidden;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 769px) {
  .homepagecontainer {
    margin-bottom: 0rem;
  }
  .Career_form_wrapper {
    padding: 0 1rem;
  }
  .Career_head {
    transform: rotate(0);
    position: unset;
    padding: 0%;
  }
  .Career_head p {
    letter-spacing: 1rem;
    margin: 2rem 0;
  }
  .Career_form_container {
    padding: 0%;
  }
  .career_form_sidebyside {
    padding: 0%;
    display: block;
  }
  .career_form_sidebyside input {
    width: 100%;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .last_form {
    padding: 0%;
  }
  .opning-box-main {
    display: block;
  }
  .current-openings {
    margin: 0 0 1.5rem 0;
  }
}
