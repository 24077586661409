.CaseStudiescontainer {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.CaseStudies-main-partofthebox {
  padding: 4rem 5rem 6rem;
  color: #fff;
  position: relative;
  z-index: 2;
}
.CaseStudies-main-heading {
  text-align: center;
  padding: 0 0 3rem;
}
.CaseStudies-main-heading h1,
.CaseStudies-main-heading p {
  margin: 0%;
  line-height: 1.5;
}
.CaseStudies-main-heading p {
  font-weight: 300;
  font-size: 18px;
}
.CaseStudies-maincontainer {
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.CaseStudies-maincontainer-main {
  margin-bottom: 5rem;
}
.main-casebox {
  padding: 1rem 0 1rem 2rem;
  margin: 0 1rem 3rem;
}
.main-casebox:nth-child(1) {
  border-left: 2px solid rgba(255, 203, 49, 0.5);
}
.main-casebox:nth-child(2) {
  border-left: 2px solid rgba(237, 27, 44, 0.5);
}
.main-casebox:nth-child(3) {
  border-left: 2px solid rgba(255, 203, 49, 0.5);
}
.main-casebox:nth-child(4) {
  border-left: 2px solid rgba(237, 27, 44, 0.5);
}
.main-casebox:nth-child(5) {
  border-left: 2px solid rgba(255, 203, 49, 0.5);
}
.main-casebox:nth-child(6) {
  border-left: 2px solid rgba(237, 27, 44, 0.5);
}
.main-casebox img {
  width: 100%;
  opacity: 0.7;
}
.main-casebox h4 {
  font-size: 20px;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.main-casebox p {
  margin: 0%;
}
.CaseStudies-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.CaseStudies-box-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 769px) {
  .CaseStudies-main-partofthebox {
    padding: 3rem 1rem;
  }
  .CaseStudies-maincontainer {
    display: block;
  }
  .heading-box {
    margin-bottom: 2rem;
  }
  .CaseStudies-box {
    display: block;
    padding: 0%;
  }
  .CaseStudies-box-main {
    display: block;
  }
  .box-for-background-carrer-circule-1 {
    top: 20rem;
    left: 10rem;
    width: 300px;
    height: 200px;
  }
  .box-for-background-carrer-circule-2 {
    top: 30rem;
    left: -5rem;
    width: 250px;
    height: 250px;
  }
  .box-for-background-carrer-circule-3 {
    bottom: 50%;
    right: unset;
    left: 10rem;
    width: 250px;
    height: 250px;
    opacity: 0.4;
  }
}
