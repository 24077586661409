.Aboutucontainer {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  color: #000;
}
.About-main-partofthebox {
  padding: 10rem 5rem 7rem;
  color: #000;
  position: relative;
  z-index: 2;
}
#AboutUs .detailes-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#AboutUs .detailes-box p {
  color: #000;
  margin-bottom: 0%;
}
.about-maincontainer {
  display: grid;
  grid-template-columns: 2fr 4fr;
}
.heading-box {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border-right: 12px solid;
  border-image: linear-gradient(to bottom, #ffcc31, #ed1b2e) 1 100%;
}
.heading-box img {
  width: 80%;
}
.heading-box p {
  font-weight: 300;
  font-size: 18px;
}
.heading-box h1,
.heading-box p {
  margin: 0%;
}
#AboutUs .detailes-box {
  padding-left: 3rem;
}
.detailes-box p {
  margin-top: 0;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
}
.detailes-box {
  padding: 0 2rem;
}
.box-for-background-About-circule {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%) rotate(-45deg);
  background: linear-gradient(to bottom right, #ed1b2e, #ffcc31);
  width: 15vw;
  height: 10vw;
  border-radius: 50em;
  opacity: 0.6;
}
.gradient-text {
  margin-bottom: 0%;
  background-color: white;

  background-image: linear-gradient(
    to right,
    #ed1b2e,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31
  );

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.gradient-text-p {
  font-weight: 900;
  margin-top: 0%;
  background-color: white;

  background-image: linear-gradient(
    to right,
    #ed1b2e,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31,
    #ffcc31
  );

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
@media only screen and (max-width: 769px) {
  .About-main-partofthebox {
    padding: 5rem 1rem;
  }
  .about-maincontainer {
    display: block;
  }
  .about-maincontainer .heading-box {
    padding-bottom: 2rem;
  }
  .about-maincontainer .detailes-box {
    padding: 0 1rem;
  }
  .pramotionVideo-main-partofthebox {
    height: auto !important;
  }
}
