.main_wrapper_nev {
  /*  background-image: linear-gradient(
    0deg,
    transparent 0%,
    rgba(255, 255, 255, 0.85) 55%,
    rgb(255, 255, 255) 100%
  ); */
  position: fixed;
  width: 100%;
  margin: 0%;
  display: flex;
  justify-content: space-between;
  height: 6rem;
  padding: 0 5rem !important;
  align-items: center;
  padding: 0;
  z-index: 3;
}

.sm_navlist {
  padding: 1rem 4rem 1rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
}
.sm_nevlinks {
  padding: 20px 0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.57vw;
  cursor: pointer;
  color: rgb(150, 150, 150);
  transition: 0.5s ease all;
}
.sm_nevlinks::after {
  content: "";
  height: 10px;
  width: 100px;
  background-color: #20222e;
}
.sm_nevlinks .active {
  color: #000;
  text-decoration-color: rgb(122, 122, 122);
}
.sm_nevlinks:hover {
  color: #000;
  text-decoration-color: rgb(122, 122, 122);
}

.assistebtn {
  float: right;
  background-color: #1e2447;
  border: 1px solid white;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.5s ease all;
  font-size: 15px;
  font-weight: 500;
}
.assistebtn:hover {
  background-color: #fff;
  border: 1px solid #1e2447;
  color: #1e2447 !important;
}
.hamburdermenu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  padding: 0.5rem;
  border-radius: 50em;
  position: relative;
}
.hamburdermenu p {
  margin: 0%;
  padding: 0 0 0 1rem;
  font-weight: 700;
}
.hamburdermenu img {
  width: 50px;
}
.hamburdermenu i {
  font-size: 30px !important;
}

.mobile_assinsbtn_container {
  display: none;
}
.mobileversion {
  display: none;
}
.sm_nav_topbar {
  margin: 0%;
  text-align: center;
}
.sm_nevlinks_container {
  width: 22vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
  background-color: white;
  transition: all 1s ease;
  transform: translateX(100%);
  box-shadow: 5px 10px 15px #aaa;
}
.sm_nevlinks_container.active {
  transform: translateX(0%);
}
.navbar-aboutus {
  padding-top: 3rem;
}
.navbar-aboutus p {
  font-size: 20px;
}
@media only screen and (max-width: 991px) {
  .sm_nevlinks_container {
    width: 30vw;
  }
}
@media only screen and (max-width: 769px) {
  .main_wrapper_nev {
    padding: 0 1rem !important;
  }
  .sm_nevlinks_container {
    width: 100%;
  }
  .sm_navlist {
    padding: 1rem 2rem;
  }
  .sm_nevlinks {
    font-size: 20px;
  }
  .sm_logo_container {
    z-index: 9;
  }
  .assinsbtn_container {
    display: none;
  }
  .mobile_assinsbtn_container {
    display: block;
  }

  .smlogo {
    fill: #1e2447;
  }

  .pcversion {
    display: none;
  }
  .mobileversion {
    display: block;
    text-align: center;
  }
  .sm_nevlinks:hover {
    color: #1e2447;
  }
}
@media screen and (min-width: 769px) and (orientation: portrait) {
  .main_wrapper_nev {
    grid-template-columns: 1px 1fr 3fr 1fr 1px;
  }
}
