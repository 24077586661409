.pramotionVideocontainer {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.pramotionVideo-main-partofthebox {
  display: flex;
  align-items: center;
  height: 65vh;
  position: relative;
}
.video-overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 0;
}
.videoplay-pouse {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoplay-pouse img {
  height: 50px;
  width: 50px;
}
.videoTag {
}
