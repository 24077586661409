.partnercontainer {
  margin-top: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.partner-main-partofthebox {
  padding: 4rem 5rem;
  color: #fff;
  position: relative;
  z-index: 2;
}
.partner-main-heading {
  text-align: center;
  padding: 0 0 3rem;
}
.partner-main-heading h1,
.partner-main-heading p {
  margin: 0%;
  line-height: 1.5;
}
.partner-main-heading p {
  font-weight: 300;
  font-size: 18px;
}
.partners-images-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.partners-images-box img {
  opacity: 0.6;
  margin: 0 0 2rem 0;
}
@media only screen and (max-width: 769px) {
  .partner-main-partofthebox {
    padding: 3rem 1rem;
  }
  .partners-images-box img {
    width: 150px;
  }
}
