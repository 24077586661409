.capabilities-wrapper {
  background-color: white;
  padding: 2rem 5rem 5rem;
}
.capabilities-wrapper-inner {
  display: grid;
  grid-template-columns: 1fr 5fr;
}
.capabilities-left-headding {
  position: relative;
}
.left-headding-container {
  display: inline-block;
  left: 75%;
  top: 75%;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.capabilities-left-headding .left-headding-container p {
  margin: 0%;

  background-color: white;

  background-image: linear-gradient(to right, #ed1b2e, #ffcc31);

  background-size: 100%;
  background-repeat: repeat;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 6.3rem;
  font-weight: 700;
  text-transform: uppercase;
}
.capabilities-right-detailes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.capabilities-right-detailes-inner {
  display: flex;
  justify-content: center;
}
.detailes-inner-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}
.detailes-inner-container img {
  height: 150px;
}
.detailes-inner-container h3 {
  font-size: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: 0%;
}
.detailes-inner-container p {
  font-size: 16px;
  height: 115px;
}
.detailes-inner-container a {
  padding: 0.4rem 1.5rem;
  background: linear-gradient(to right, #ed1b2e, #ffcc31);
  color: white;
  border-radius: 50rem;
  font-size: 13px;
  transition: 0.2s ease all;
}
.link-container {
}
.detailes-inner-container a:hover {
  font-weight: 600;
  -webkit-text-fill-color: white;
  -moz-text-fill-color: white;
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .capabilities-wrapper {
    padding: 2rem 2rem 5rem;
  }
}
@media only screen and (max-width: 769px) {
  .capabilities-wrapper-inner {
    display: block;
  }
  .left-headding-container {
    position: unset;
    transform: rotate(0deg);
    display: block;
  }
  .capabilities-left-headding .left-headding-container p {
    font-size: 10vw;
    text-align: center;
  }
  .capabilities-right-detailes {
    display: flex;
    flex-wrap: wrap;
  }
  .capabilities-wrapper {
    padding: 3rem 1rem 3rem;
  }
}

@media only screen and (max-width: 660px) {
  .detailes-inner-container p {
    height: auto;
  }
}
